import * as React from "react"
import { Grid, Segment, Container } from 'semantic-ui-react'
import SiteHeader from '../components/site-header'
import MenuCategory from '../components/menu-category'
import { Helmet } from "react-helmet"
import _ from 'lodash'
import { graphql } from 'gatsby'

export const query = graphql`
{
  allAirtablecategoriesMumbai(sort: {order: [ASC, ASC], fields: [rowIndex, rowIndex]}, filter: {table: {eq: "categories"}}) {
    edges {
      node {
        rowIndex,
        data {
          categoryName,
          categoryDescription,
          categoryFooter,
          formattingOptions,
          images {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 1200, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          },
          dishes {
						recordId,
            rowIndex,
            data {
              recordId,
							dishName,
              price,
              hasJainOption,
              body,
              lightText,
              images {
        				localFiles {
          				childImageSharp {
            				gatsbyImageData(width: 1200, placeholder: NONE, layout: CONSTRAINED)
          				}
        				}
              }
            }
          }
        }
      }
    }
  }
  site {
    siteMetadata {
      title,
      versionNumber,
      siteUrl,
    }
  }
}
`

const MenuPage = ({data}) => {
  const arrayOfCategories = _.orderBy(data.allAirtablecategoriesMumbai.edges, ['rowIndex'], ['asc']).map(cat => cat.node.data)
  return(
    <Container>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mumbai - {data.site.siteMetadata.title}</title>
      </Helmet>
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column>
            <Segment className="triline-container clearing">
              <SiteHeader />
              {arrayOfCategories.map((cat, index) => (
                <MenuCategory category={cat} key={`${cat.categoryName}-${index}`} menuItems={cat.dishes} />

              ))}
              <div className="swati-divider">
                <hr/><hr/><hr/>
              </div>
              <Container className="main-footer">
                <ul className="responsive-footer-list">
                  <li>we apologise if your selection is not available</li>
                  <li className="hidden-xs"> • </li>
                  <li>we may not be able to change or cancel orders</li>
                </ul>
                <ul className="responsive-footer-list">
                  <li>items on our menu are based on our own recipes</li>
                  <li className="hidden-xs"> • </li>
                  <li>menu and prices subject to change without notice</li>
                </ul>
                <ul className="responsive-footer-list">
                  <li>our food may contain nuts, milk products, cereals containing gluten, vegetable oils and ghee</li>
                </ul>
                <div className="clearing" style={{clear: "both", float: "none"}}/>
                <br/>
                <p>restaurant timing: 11:45 am to 10:30 pm</p>
                <br/>
                <div className="responsive-footer">
                  * jain option available
                </div>
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column width={8} style={{textAlign: 'left'}}>
                        GST extra @ 5%
                    </Grid.Column>
                    <Grid.Column width={8} style={{textAlign: 'right'}}>
                      Mumbai {data.site.siteMetadata.versionNumber}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

export default MenuPage
